<template>
    <BaseContainer :hideMenu="true">
        <span slot="principal">
            <v-container fluid style="background-color: #485461;
                background-image: linear-gradient(315deg, #485461 0%, #28313b 74%);">
                <v-row align="center" justify="center">
                    <v-col cols="12" sm="8" md="4">
                        <v-card class="elevation-12">
                            <v-toolbar dark>
                                <v-spacer />
                                <v-img
                                    max-width="120"
                                    src="@/assets/images/zaper-menu.png"
                                />
                                <v-spacer />
                            </v-toolbar>
                            <v-card-text>
                                <v-form>
                                    <v-text-field
                                        label="E-mail"
                                        ref="login"
                                        prepend-icon="person"
                                        type="text"
                                        v-model="email"
                                        autocomplete="username"
                                        @keypress.enter="$refs.password.focus()"
                                    />

                                    <v-text-field
                                        label="Senha"
                                        ref="password"
                                        v-model="password"
                                        prepend-icon="lock"
                                        @keypress.enter="login"
                                        autocomplete="current-password"
                                        :type="showPassword ? 'text' : 'password'"
                                        @click:append="showPassword = !showPassword"
                                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                    />
                                </v-form>

                                <v-btn
                                    v-if="false"
                                    @click="passwordForgot"
                                    small
                                    text
                                >Esqueci minha senha</v-btn>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn
                                    @click="login"
                                    :loading="loading"
                                    class="white--text"
                                    color="#e50914"
                                    block
                                    large
                                >Entrar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>

            <v-snackbar v-model="snackbar" color="red">
                Email ou senha inválido
                <v-btn color="white" text @click="snackbar=false">Fechar</v-btn>
            </v-snackbar>

            <AssinaturaCartao
                :visible="visible1"
                :empresa="empresa"
                @paid="onPaid('cartao')"
                @close="visible1 = false"
            />

            <AssinaturaBolix
                :visible="visible3"
                :empresa="empresa"
                @paid="onPaid('bolix', $event)"
                @close="visible3 = false"
            />

            <BolixPendente
                :visible="visible2"
                v-model="url"
                @close="visible2 = false"
            />

            <AssinaturaConcluida
                v-model="cobranca"
                :visible="visible4"
                @close="visible4 = false"
            />
        </span>
    </BaseContainer>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import BaseContainer from '@/components/BaseContainer'
import BolixPendente from '@/pages/assinatura/BolixPendente';
import AssinaturaCartao from '@/pages/assinatura/AssinaturaCartao';
import AssinaturaBolix from '@/pages/assinatura/AssinaturaBolix';
import AssinaturaConcluida from '@/pages/assinatura/AssinaturaConcluida';

export default {
    name: 'Login',

    components: {
        BaseContainer,
        AssinaturaCartao,
        AssinaturaBolix,
        BolixPendente,
        AssinaturaConcluida,
    },

    data: () => ({
        url: '',
        email: '',
        password: '',
        empresa: {},
        cobranca: {},
        loading: false,
        snackbar: false,
        showPassword: false,
        visible1: false,
        visible2: false,
        visible3: false,
        visible4: false,
    }),

    mounted() {
        this.autoLogin();
    },

    methods: {
        ...mapActions([
            'clearDashboardData',
        ]),

        ...mapMutations({
            setUsuario: 'setUsuario',
        }),

        autoLogin() {
            const { email, senha } = this.$route.query;

            if (email && senha) {
                this.email = email;
                this.password = senha;

                this.login();
            }
        },

        login() {
            if (!this.email) {
                this.notify('Informe o e-mail', 'warning');
                return;
            }
            if (!this.password) {
                this.notify('Informe a senha', 'warning');
                return;
            }

            this.loading = true;
            this.$http.post('login', {
                email: this.email,
                password: this.password
            }).then(async (resp) => {
                const data = resp.data;

                if (data.token) {
                    this.makeLogin(data);
                    return;
                }

                if (data.financeiro) {
                    if (data.financeiro.formaPagamento === undefined) {
                        alert('Assinatura não identificada \nPor favor, contate o suporte!');
                        return;
                    }

                    if (data.financeiro.formaPagamento === 'bolix') {
                        if (data.financeiro.primeiraCobranca) {
                            this.empresa = data.financeiro.empresa;
                            this.visible3 = true;
                            return;
                        }
                        this.url = data.financeiro.download;
                        this.visible2 = true;
                    }

                    if (data.financeiro.formaPagamento === 'cartao') {
                        if (data.financeiro.pagamentoFalhou) {
                            alert('Houve uma falha na identificação do pagamento \nPor favor, contate o suporte!');
                            return;
                        }
                        if (data.financeiro.pagamentoEmProcessamento) {
                            this.notify('Pagamento em processamento, aguarde alguns intantes e tente novamente', 'warning');
                            return;
                        }
                        if (data.financeiro.primeiraCobranca) {
                            this.empresa = data.financeiro.empresa;
                            this.visible1 = true;
                            return;
                        }
                    }
                    return;
                }

                this.notify('Usuário ou senha inválido', 'warning');
            })
            .catch(() => (this.notify('Não foi possivel realizar login', 'warning')))
            .finally(() => (this.loading = false))
        },

        makeLogin(data) {
            this.clearDashboardData();

            this.setUsuario(data);
            sessionStorage.setItem('token', data.token);

            this.$http.defaults.headers.common = {
                Authorization: `Bearer ${data.token}`,
            };

            this.$router.push('/');
        },

        onPaid(formaPagamento, data) {
            if (formaPagamento == 'bolix') {
                this.visible3 = false;

                this.visible4 = true;
                this.cobranca = data;
            }

            if (formaPagamento == 'cartao') {
                alert('Seu pagamento está sendo processado e poderá levar até 5 minutos,\naguarde e faça login novamente!');
                location.reload();
            }
        },

        passwordForgot() {
            this.$router.push('/forgot-password');
        },
    }
}
</script>

<style scoped>
.container  {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.container .row {
    margin: 0 auto;
}
</style>
